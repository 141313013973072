import { createApp } from 'vue'
import App from './App.vue'
import { setupRouter } from './router'
import store from './store'
import './registerServiceWorker'

require('./assets/css/style.css')

const router = setupRouter()

createApp(App).use(store).use(router).mount('#app')
