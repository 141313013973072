<template>
  <div id="app">
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<style lang="scss">
  #app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-flex-direction: column;
    -webkit-display: flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    height: 100%;
    width: 100%;
  }

  .slide-enter-active {
    animation: slide-in ease-out .3s forwards;
    -webkit-animation: slide-in ease-out .3s forwards;
  }

  .slide-leave-active {
    animation: slide-out ease-out .3s forwards;
    -webkit-animation: slide-out ease-out .3s forwards;
  }

  @keyframes slide-in {
    from {
      transform: translateX(200px);
      -webkit-transform: translateX(200px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-out {
    from {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-200px);
      -webkit-transform: translateX(-200px);
      opacity: 0;
    }
  }
</style>
